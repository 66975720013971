// React
import React, { UIEvent } from "react";
// Components
import { Menu } from "./Menu";
// Styles
import "./intro.css";
// Assets
import vignetteVAUBAN from "../../style/assets/intro/vignetteVAUBAN.jpg";
import timescopeLogo from "../../style/assets/intro/timescopeLogo.png";
import arrasLogo from "../../style/assets/intro/logo.jpg";
import arrasCUALogo from "../../style/assets/intro/logoCUA.png";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";
import { trads } from "../../datas/trads";

export const Intro = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	return (
		<React.Fragment>
			<div className='intro'>
				<div className='intro-header'>
					<div className='intro-header-logo'></div>
				</div>
				<div className='intro-overflowedContainer'>
					<div className='intro-overflowed'>
						<div className='intro-explanations'>
							<div className='intro-explanations-title'>{(trads.find((t: any) => t.id === language)?.travelInTime)?.toUpperCase()}</div>
							<div className='intro-explanations-text'>
								<div className='intro-explanations-textLine'>
									{trads.find((t: any) => t.id === language)?.introDesc}
								</div>
							</div>
						</div>
						<div className='intro-bloc'>
							<div className='intro-bloc-header'>{(trads.find((t: any) => t.id === language)?.vaubanTitle)?.toUpperCase()}</div>
							<div
								className='intro-bloc-videoTrailer btnStart'
								onClick={() => {
									props.next("vauban");
								}}
								style={{ backgroundImage: `url(${vignetteVAUBAN})` }}
							>
								<div className='intro-bloc-videoTrailer-playIcon'></div>
								{/* <div className='intro-bloc-videoTrailer-newIcon'>
									<div className='intro-bloc-videoTrailer-newIconText'>Nouveau</div>
								</div> */}
							</div>
							<div
								className='intro-bloc-infos'
								onClick={() => {
									props.infos("vauban");
								}}
							>
								<div className='intro-bloc-infos-more'>{trads.find((t: any) => t.id === language)?.knowMore}</div>
								<div className='intro-bloc-infos-timer'>1:55</div>
							</div>
						</div>
						<div className='intro-logos'>
							<div
								className='intro-logos-logo'
								style={{ backgroundImage: `url(${timescopeLogo})` }}
							></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${arrasLogo})` }}></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${arrasCUALogo})`, backgroundSize: 'contain' }}></div>
						</div>
					</div>
					<div id='intro-fade-bottom' className='intro-fade-bottom'></div>
				</div>
			</div>
		</React.Fragment>
	);
};
