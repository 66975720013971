// React
import React, { useEffect, useState } from "react";
// CSS
import "./infoPage.css";
// Datas
import { experiences } from "../../datas/experiences";
// Assets
import timescopeLogo from "../../style/assets/intro/timescopeLogo.png";
import arrasLogo from "../../style/assets/intro/logo.jpg";
import arrasCUALogo from "../../style/assets/intro/logoCUA.png";
import { GetTrad } from "../Partials/GetTrad";
import { trads } from "../../datas/trads";
import { IRootState } from "../../reducers";
import { useSelector } from "react-redux";

export const InfoPage = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user)
	const [experience, setExperience] = useState<any>();

	useEffect(() => {
		setExperience(experiences.find((e: any) => e.id === props.infoPage));
	}, [props.infoPage]);

	return (
		<React.Fragment>
			{experience && (
				<div className='infoPage'>
					<div className='infoPage-overflowed'>
						<div className='infoPage-band' onClick={() => props.hide()}>
							<div className='infoPage-band-cross'></div>
						</div>
						<div
							className='infoPage-title'
						>
							{(trads.find((t: any) => t.id === language)?.vaubanTitle)?.toUpperCase()}
						</div>
						<div
							className='infoPage-affiche'
							style={{ backgroundImage: `url(${experience?.affiche.normal})` }}
						></div>
						<div className='infoPage-content'>
							{experience.content.map((c: any, index: number) => {
								switch (c.type) {
									case "title":
										return (
											<div key={'expcontent-' + index} className='infoPage-content-title'>
												<GetTrad value={c.value} language={language} />
											</div>
										);
									case "text":
										return (
											<div key={'expcontent-' + index} className='infoPage-content-text'>
												<GetTrad value={c.value} language={language} />
											</div>
										);
								}
							})}
						</div>
						<div
							className='infoPage-share'
							onClick={() => {
								navigator.share({
									url: "/" + experience.id,
									text: experience.share.header,
									title: experience.share.header
								});
							}}
						>
							<div className='infoPage-share-icon'></div>
							<div className='infoPage-share-text'>{trads.find((t: any) => t.id === language)?.share}</div>
						</div>
						<div className='intro-logos'>
							<div
								className='intro-logos-logo'
								style={{ backgroundImage: `url(${timescopeLogo})` }}
							></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${arrasLogo})` }}></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${arrasCUALogo})`, backgroundSize: 'contain' }}></div>
						</div>
					</div>
				</div>
			)}

			{/* <div id='intro-fade-bottom' className='intro-fade-bottom'></div> */}
		</React.Fragment>
	);
};
