export const trads = [
	{
		id: "fr",

		// Language Page
		selectLanguage: "Avant de profiter de l'expérience, sélectionnez votre langue :",

		// Intro Page
		travelInTime: "Voyagez à travers le temps",
		introDesc: "Découvrez l'histoire de la citadelle d'Arras.",
		knowMore: "En savoir plus",

		// Credits
		expCredits: "Cette expérience de « voyage dans le temps » est le fruit d'un partenariat entre l'Office de Tourisme des Loisirs et des Congrès Arras Pays d’Artois, la Communauté Urbaine d'Arras, la ville d'Arras et la société Timescope, pionnière de l'innovation culturelle au service de la valorisation du patrimoine.",

		// VAUBAN
		vaubanTitle: "La Citadelle d’Arras",
		eatsMoreThan300:
			"Plongez en 1678, au cœur du chantier de la Citadelle d'Arras, cette fortification pentagonale commandée par Louis XIV au fameux ingénieur militaire Sébastien Le Prestre de Vauban, pour défendre la France et surveiller la population arrageoise.",
		eatsFiguresList:
			"Imaginez-la bientôt prête à accueillir 1500 soldats… Cette citadelle deviendra un modèle de fortification de plaine.",
		eatsTimescopeExp:
			"Faites pivoter votre téléphone mobile à 360° tout autour de vous et augmentez le volume pour pleinement profiter de cette expérience immersive.",

		// Endpage
		travelProposedFor:
			"Cette expérience fait partie d’un parcours de découverte du patrimoine d’Arras - Pays d’Artois. Retrouvez des expériences de voyage dans le temps similaires sur la Place des Héros d’Arras, au mémorial de Neuville Saint-Vaast et sur les bords de Scarpe.",
		travelProposedBy: "Une expérience en réalité virtuelle réalisée par %lTimescope%l",
		share: "Partager",
	},
	{
		id: "en",

		// Language Page
		selectLanguage: "Before enjoying experience, please select your language:",

		// Intro Page
		travelInTime: "Travel in time",
		introDesc: "Discover the history of citadel of Arras.",
		knowMore: "Know more",

		// Credits
		expCredits: "This “time travel” experience is the result of a partnership between Arras Pays d'Artois Convention and Visitors Bureau, the Urban Community of Arras, the city of Arras and the company Timescope, a pioneer in cultural innovation serving the promotion of heritage.",

		// VAUUBAN
		vaubanTitle: "The Citadel of Arras",
		eatsMoreThan300:
			"Immerse yourself in 1678, at the heart of the Citadel of Arras construction site, a pentagonal fortification commissioned by Louis XIV from the famous military engineer Sébastien Le Prestre de Vauban, with a view to defend France and monitor the people of Arras.",
		eatsFiguresList:
			"Imagine it soon ready to accommodate 1,500 soldiers… This citadel will become a model of flatland fortification.",
		eatsTimescopeExp:
			"Rotate your mobile phone 360° around you and increase the volume to fully enjoy this immersive experience.",

		// Endpage
		travelProposedFor:
			"This experience is part of a heritage discovery tour in Arras - Pays d’Artois. Find similar time-travel experiences at the Place des Héros in Arras, the Neuville Saint-Vaast Memorial, and along the banks of the Scarpe River.",
		travelProposedBy: "A virtual reality experience created by %lTimescope%l",
		share: "Share",
	},
];
