// React
import { useEffect, useState } from "react";
// Trads
import { trads } from "../../datas/trads";
// CSS
import "./landingPage.css";
// GIFs
// Images
import React from "react";
import { experiences } from "../../datas/experiences";
// Assets
import timescopeLogo from "../../style/assets/intro/timescopeLogo.png";
import arrasLogo from "../../style/assets/intro/logo.png";
import arrasCUALogo from "../../style/assets/intro/logoCUA.png";
import { GetTrad } from "../Partials/GetTrad";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";

export const LandingPage = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	useEffect(() => {
		let homeElem = document.getElementsByClassName("home");
		if (homeElem) {
			for (let elem of homeElem) {
				(elem as HTMLElement).style.pointerEvents = "all";
			}
		}

		const scrollManagment = (e?: Event) => {
			let elem = document.getElementById("landingPage-container");
			let elemOpacity = document.getElementById("landingPage-textContainer");
			if (elem && elemOpacity) {
				let { scrollHeight, scrollTop, clientHeight } = elem;

				let max = scrollHeight - clientHeight;
				let percent = ((scrollHeight - scrollTop - clientHeight) * 100) / max;

				if (percent < 0) {
					percent = 0;
				}

				elemOpacity.style.setProperty("--maskHeight", 100 - percent + "%");

				let elemArrow = document.getElementById("landingPage-textScroll");
				if (elemArrow) {
					if (scrollHeight - scrollTop - 15 > clientHeight) {
						elemArrow.style.opacity = "1";
					} else {
						elemArrow.style.opacity = "0";
					}
				}
			}
		};

		scrollManagment();

		document.getElementById("landingPage-container")?.addEventListener("scroll", (e: Event) => scrollManagment(e));
	}, []);

	const [experience, setExperience] = useState<any>();

	useEffect(() => {
		setExperience(experiences.find((e: any) => e.id === props.landingPage));
	}, [props.landingPage]);

	return (
		<React.Fragment>
			{experience && (
				<div className='landingPage' style={{ backgroundImage: `url(${experience.affiche.blur})` }}>
					<div
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.6)",
						}}
					></div>
					<div className='landingPage-overflowed'>
						<div className='landingPage-band' onClick={() => document.location.reload()}>
							<div className='landingPage-band-cross'></div>
						</div>
						<div
							className='landingPage-title'
						></div>
						<div className='landingPage-content'>
							{experience.endPage.map((c: any) => {
								switch (c.type) {
									case "title":
										return (
											<div className='landingPage-content-title'>
												<GetTrad value={c.value} language={language} />
											</div>
										);
									case "text":
										return (
											<div className='landingPage-content-text'>
												<GetTrad value={c.value} language={language} />
											</div>
										);
									case "share":
										return (
											<div
												className='landingPage-share'
												onClick={() => {
													navigator.share({
														url: "/" + experience.id,
														text: experience.share.header,
														title: experience.share.header,
													});
												}}
											>
												<div className='landingPage-share-icon'></div>
												<div className='landingPage-share-text'>{trads.find((t: any) => t.id === language)?.share}</div>
											</div>
										);
								}
							})}
						</div>
						<div className='intro-logos'>
							<div
								className='intro-logos-logo'
								style={{ backgroundImage: `url(${timescopeLogo})` }}
							></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${arrasLogo})` }}></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${arrasCUALogo})`, backgroundSize: 'contain' }}></div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};
