import React, { useEffect } from 'react';
// Icons
import speaker from '../../style/assets/icons/speaker.png';
import speakerOff from '../../style/assets/icons/speakerOff.png';
import copyright from '../../style/assets/icons/copyright.png';
import subtitles from '../../style/assets/icons/subtitles.png';
import subtitlesOff from '../../style/assets/icons/subtitlesOff.png';
import close from '../../style/assets/icons/close.png';

export const Menu = (props: any) => {
	useEffect(() => {}, [props.sound]);

	const goCredits = () => {
		if (props.displayCredits) {
			let canvasElem = document.getElementById('viewport');

			if (canvasElem) {
				canvasElem.style.width = '0';
				canvasElem.style.height = '0';
			}
			props.displayCredits(true);
		}
	};

	return (
		<React.Fragment>
			<div
				id='button0'
				className='clickable'
				onClick={() => {
					document.location.reload();
				}}
				style={{
					position: 'absolute',
					top: '20px',
					right: '20px',
					width: '30px',
					height: '30px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					borderRadius: '50%',
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					backgroundImage: `url(${close})`,
					backgroundSize: '20px 20px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
			></div>

			<div
				id='button1'
				className='clickable'
				onClick={() => {
					props.toggleSound();
				}}
				style={{
					position: 'absolute',
					top: '70px',
					right: '20px',
					width: '30px',
					height: '30px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					borderRadius: '50%',
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					backgroundImage: props.sound ? `url(${speaker})` : `url(${speakerOff})`,
					backgroundSize: '20px 20px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
			></div>

			{/* <div
				id='button2'
				className='clickable'
				onClick={() => {
					props.toggleSubtitles();
				}}
				style={{
					position: 'absolute',
					top: '120px',
					right: '20px',
					width: '30px',
					height: '30px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					borderRadius: '50%',
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					backgroundImage: props.subtitles ? `url(${subtitles})` : `url(${subtitlesOff})`,
					backgroundSize: '18px 18px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
			></div> */}

			<div
				id='button3'
				className='clickable'
				style={{
					position: 'absolute',
					top: '120px',
					right: '20px',
					width: '30px',
					height: '30px',
					// top: '170px',
					// right: '20px',
					// width: '30px',
					// height: '30px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					borderRadius: '50%',
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					backgroundImage: `url(${copyright})`,
					backgroundSize: '20px 20px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
				onClick={() => {
					goCredits();
				}}
			></div>
		</React.Fragment>
	);
};
