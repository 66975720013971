// Eats
import vaubanAfficheNormalImg from "../style/assets/infoPage/vauban/affiche.jpg";
import vaubanAfficheBlurImg from "../style/assets/infoPage/vauban/afficheBlur.jpg";
import vaubanVignette from "../style/assets/intro/vignetteVAUBAN.jpg";

export const experiences = [
	{
		id: "vauban",
		title: {
			value: "vaubanTitle",
		},
		affiche: {
			normal: vaubanAfficheNormalImg,
			blur: vaubanAfficheBlurImg
		},
		content: [
			// {
			// 	type: "title",
			// 	value: "eatsPeriod",
			// },
			{
				type: "text",
				value: "eatsMoreThan300",
			},
			{
				type: "text",
				value: "eatsFiguresList",
			},
			{
				type: "text",
				value: "eatsTimescopeExp",
			},
		],
		endPage: [
			{
				type: "text",
				value: "travelProposedFor",
			},
			{
				type: "share",
			},
			{
				type: "text",
				value: "travelProposedBy",
			}
		],
		movie: {
			title: "vaubanTitle",
			vignette: vaubanVignette,
			new: true,
			duration: "1:55",
		},
		share: {
			header: "La Citadelle d’Arras.",
		},
	},
];
