// React
import React, { useEffect, useState } from "react";
// Components
// Styles
import "./language.css";
// Assets
import vignetteVAUBAN from "../../style/assets/intro/vignetteVAUBAN.jpg";
import timescopeLogo from "../../style/assets/intro/timescopeLogo.png";
import arrasLogo from "../../style/assets/intro/logo.png";
import jepLogo from "../../style/assets/intro/jepLogo.png";

import frFlag from "../../style/assets/language/flags/fr.png"
import enFlag from "../../style/assets/language/flags/en.png"

import { trads } from "../../datas/trads";
import { setLanguage } from "../../actions/user";
import { useDispatch } from "react-redux";

export const Language = (props: any) => {
	const dispatch = useDispatch();

	const [tradIndex, setTradIndex] = useState(0);

	useEffect(() => {
		let interval = setInterval(() => {
			let tmpTradIndex = tradIndex + 1;

			if (tmpTradIndex >= trads.length) {
				tmpTradIndex = 0;
			}
			setTradIndex(tmpTradIndex);
		}, 5000);

		return () => clearInterval(interval)
	}, [tradIndex])

	return (
		<React.Fragment>
			<div className='language'>
				<div className="language-presentation">
					{trads[tradIndex].selectLanguage}
				</div>
				<div className="language-array">
					<div className="language-flag" style={{ backgroundImage: `url(${frFlag})` }} onClick={() => {
						dispatch(setLanguage("fr"));
						props.next();
					}}></div>
					<div className="language-flag" style={{ backgroundImage: `url(${enFlag})` }} onClick={() => {
						dispatch(setLanguage("en"));
						props.next();
					}}></div>
				</div>
			</div>
		</React.Fragment >
	);
};
