// Styles
import './credits.css';
// Icons
import close from '../../style/assets/icons/close.png';
import React from 'react';
import { trads } from '../../datas/trads';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducers';

export const Credits = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	const goBack = () => {
		props.displayCredits(false);
	};

	return (
		<React.Fragment>
			<div
				className='clickable'
				style={{
					position: 'absolute',
					top: '20px',
					right: '20px',
					width: '25px',
					height: '25px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					backgroundImage: `url(${close})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
				onClick={() => {
					goBack();
				}}
			></div>
			<div
				className='credits'
				id='credits'
				style={{
					zIndex: 8000,
					position: 'absolute',
					top: 0,
				}}
			>
				<h1>Crédits</h1>
				<p style={{width: "80%", margin: "32px auto"}}>
					{trads.find((t: any) => t.id === language)?.expCredits}
				</p>
			</div>
		</React.Fragment>
	);
};
